<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <!-- <v-tooltip top> -->
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-2"
          fab
          dark
          color="purple"
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>

        <!-- <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
          Click Me
        </v-btn> -->
      </template>
      <!-- <span>Adicionar Material Complementar</span>
      </v-tooltip> -->
      <v-card class="v-card-profile">
        <v-app-bar color="primary" dark flat>
          <v-toolbar-title class="body-2">
            Material Complementar
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>

        <v-card-text>
          <v-form>
            <v-row>
              <v-col lg="12" md="12">
                <v-text-field label="Nome do arquivo" v-model="arquivo.title">
                </v-text-field>
              </v-col>
              <v-col lg="12" md="12">
                <v-text-field label="URL" v-model="arquivo.url"> </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="salvar"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import toastr from "toastr";
import { db } from "@/db";
export default {
  props: {
    curso: Object,
    moduloKey: String,
    aulaKey: String,
  },
  data() {
    return {
      dialog: false,
      arquivo: {
        contentType: "",
        size: 0,
        timeCreated: "",
        title: "",
        url: "",
      },
    };
  },
  methods: {
    salvar() {
      const arquivo = this.arquivo;
      let ementa =
        "/cursos/" +
        this.curso[".key"] +
        "/ementa/" +
        this.moduloKey +
        "/children/" +
        this.aulaKey +
        "/arquivos";
      let aulas =
        "/cursos/" +
        this.curso[".key"] +
        "/aulas/" +
        this.aulaKey +
        "/arquivos";

      if (this.arquivo.url) {
        db.ref(ementa)
          .push(arquivo)
          .then(() => {
            db.ref(aulas)
              .push(arquivo)
              .then(() => {
                console.log(arquivo)
                toastr.success(
                  "Material Complementar salvo com sucesso!",
                  "Confabular"
                );
                this.dialog = false;
                this.arquivo = {
                  contentType: "",
                  size: 0,
                  timeCreated: "",
                  title: "",
                  url: "",
                };
              })
              .catch((error) => {
                toastr.error(
                  "Algo Inesperado aconteceu: " + error.message,
                  "Confabular - Salvar arquivo em aulas"
                );
              });
          })
          .catch((error) => {
            toastr.error(
              "Algo Inesperado aconteceu: " + error.message,
              "Confabular - Salvar arquivo em ementa"
            );
          });
      }
    },
  },
};
</script>