<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card color="purple">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Editar Curso</div>

            <div class="subtitle-1 font-weight-light">Cadastro de Curso</div>
          </template>
          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    class="purple-input"
                    label="Título do curso*"
                    v-model="curso.titulo"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    label="Carga Horária"
                    class="purple-input"
                    type="number"
                    persistent-hint
                    hint="Duração em horas/aula"
                    v-model="curso.duracao"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    label="Permanência"
                    persistent-hint
                    hint="Duração em semanas a partir da data de matrícula"
                    type="number"
                    class="purple-input"
                    v-model="curso.permanencia"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Data Limite"
                    persistent-hint
                    hint="Dia limite para a conclusão"
                    type="date"
                    class="purple-input"
                    v-model="curso.dataLimite"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Valor"
                    class="purple-input"
                    v-model="curso.valor"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    suffix="%"
                    label="Percentagem Conclusão"
                    class="purple-input"
                    type="number"
                    v-model="curso.percentagemConclusao"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Whatsapp"
                    class="purple-input"
                    v-model="curso.whatsapp"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    disabled
                    label="id"
                    class="purple-input"
                    v-model="curso['.key']"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    label="Link para checkout"
                    class="purple-input"
                    v-model="curso.linkCheckout"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    label="Id do Produto do kiwify"
                    class="purple-input"
                    v-model="curso.product_id"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    label="Descrição curta"
                    class="purple-input"
                    v-model="curso.descricao"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <span class="display-1 mb-3">Visão Geral</span>
                  <ckeditor
                    :editor="editor"
                    v-model="curso.geral"
                    :config="editorConfig"
                  ></ckeditor>
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click.native="atualizar()"
                  >
                    Salvar Curso
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-row>
          <v-col cols="12" md="12" class="mt-0 pt-0">
            <base-material-card
              class="v-card-profile"
              :avatar="url ? url : curso.thumbnail || avatar"
            >
              <v-card-text class="text-center">
                <h4 class="display-2 mb-1 black--text">
                  {{ curso.titulo }}
                </h4>

                <h6 class="display-1 font-weight-light mb-1 grey--text">
                  {{ curso.descricao }}
                </h6>
                <h6 class="display-1 mb-3 grey--text font-weight-light">
                  {{ curso.duracao }} horas/aula
                </h6>
                <v-checkbox
                  v-model="curso.liberarCatalogo"
                  label="Liberar para Catálogo"
                  :value="true"
                ></v-checkbox>
                <v-text-field
                    label="URL Thumbnail"
                    class="purple-input"
                    v-model="curso.thumbnail"
                  />
                <v-file-input
                  ref="foto"
                  class="d-none"
                  accept="image/png, image/jpeg, image/bmp"
                  type="file"
                  @change="onFileChanged"
                ></v-file-input>
                <v-btn
                  @click.native="uploadImagem"
                  color="success"
                  rounded
                  class="mr-0"
                >
                  {{ curso.thumbnail ? "Mudar Foto" : "Inserir Foto" }}
                </v-btn>
              </v-card-text>
            </base-material-card>
          </v-col>
          <v-col cols="12" md="12">
            <base-material-card color="purple">
              <template v-slot:heading>
                <div class="display-2 font-weight-light">Apostila</div>

                <!--  -->
              </template>

              <v-card-text class="text-center">
                <div class="display-1 font-weight-light mb-5" v-if="curso.guia">
                  <v-btn
                    text
                    :link="true"
                    :href="curso.guia"
                    target="guiaCurso"
                    >Ver Apostila</v-btn
                  >
                </div>
                <v-text-field
                    label="URL Apostila"
                    class="purple-input"
                    v-model="curso.guia"
                  />
              </v-card-text>
            </base-material-card>
          </v-col>
          <v-col cols="12" md="12">
            <base-material-card color="purple">
              <template v-slot:heading>
                <div class="display-2 font-weight-light">Declaração (Modelo)</div>

                <!--  -->
              </template>

              <v-card-text class="text-center">
                
                <v-text-field
                    label="URL Declaração"
                    class="purple-input"
                    v-model="curso.declaracao"
                  />
              </v-card-text>
            </base-material-card>
          </v-col>
          <v-col cols="12" md="12">
            <base-material-card color="purple">
              <template v-slot:heading>
                <div class="display-2 font-weight-light">Conteúdo</div>

                <!--  -->
              </template>

              <v-card-text class="text-center">
                <div class="display-1 font-weight-light mb-5" v-if="curso.conteudoPDF">
                  <v-btn
                    color="success"
                    rounded
                    :link="true"
                    :href="curso.conteudoPDF"
                    target="conteudo"
                    >Ver Conteudo</v-btn
                  >
                </div>
                <v-text-field
                    label="URL Conteúdo"
                    class="purple-input"
                    v-model="curso.conteudoPDF"
                  />
              </v-card-text>
            </base-material-card>
          </v-col>
          <v-col cols="12" md="12">
            <base-material-card color="purple">
              <template v-slot:heading>
                <div class="display-2 font-weight-light">Certificado</div>

                <!--  -->
              </template>

              <v-card-text class="text-center">
                <div
                  class="display-1 font-weight-light mb-5 text-center align-center"
                  v-if="curso.certificado"
                >
                  <v-img
                    lazy-src="@/assets/thumb_default.webp"
                    max-width="100%"
                    eager
                    :src="curso.certificado"
                    class="align-center"
                  ></v-img>
                </div>
                <v-checkbox
                  v-model="curso.liberarCertificado"
                  label="Liberar Certificado"
                  :value="true"
                ></v-checkbox>
                <v-file-input
                  ref="certificado"
                  class="d-none"
                  accept="image/jpeg"
                  type="file"
                  @change="onFileChangeCertificado"
                ></v-file-input>
                  <v-text-field
                    label="URL Certificado"
                    class="purple-input"
                    v-model="curso.certificado"
                  />
                <v-btn
                  @click.native="uploadCertificado"
                  color="success"
                  rounded
                  class="mr-0"
                >
                  {{
                    curso.certificado
                      ? "Mudar Certificado"
                      : "Inserir Certificado"
                  }}
                </v-btn>
              </v-card-text>
            </base-material-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="12">
        <base-material-card color="purple">
          <template v-slot:heading>
            <div class="display-2 font-weight-light float-sm-left">Ementa</div>

            <div class="float-sm-right">
              <v-btn
                class="mx-2"
                fab
                dark
                color="purple"
                small
                @click="adicionarModulo"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </div>
          </template>
          <v-card-text class="text-center">
            <v-expansion-panels v-model="modulos">
              <template v-for="(modulo, index) in curso.ementa">
                <v-expansion-panel :key="index">
                  <v-expansion-panel-header class="purple lighten-4">
                    <template v-slot:default="{ open }">
                      <v-row no-gutters>
                        <v-col cols="4">{{ modulo.title }}</v-col>
                        <v-col cols="8" class="text--secondary">
                          <v-fade-transition leave-absolute>
                            <span v-if="open" key="0">
                              Preencha com as informações do módulo
                              <v-btn
                                class="mx-2"
                                dark
                                color="purple"
                                small
                                @click="adicionarAula(modulo, index)"
                              >
                                <v-icon dark left> mdi-plus </v-icon>
                                Adicionar aula
                              </v-btn>
                            </span>
                            <span v-else key="1">
                              {{ modulo.duracao }}
                            </span>
                          </v-fade-transition>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-text-field
                      v-model="modulo.title"
                      label="Nome do módulo"
                      placeholder="Escreva o nome do módulo"
                    ></v-text-field>
                    <v-text-field
                      v-model="modulo.duracao"
                      label="Carga Horária do módulo"
                      placeholder="Carga Horária do módulo em horas"
                    ></v-text-field>
                    <v-text-field
                      v-model="modulo.dataPublicacao"
                      label="Data de publicação"
                      placeholder="data de publicação do módulo"
                      type="datetime-local"
                    ></v-text-field>
                    <v-text-field
                      :value="index"
                      disabled
                      label="chave"
                    ></v-text-field>
                    <v-expansion-panels v-model="aulas" class="my-5">
                      <template v-for="(aula, indexAula) in modulo.children">
                        <v-expansion-panel :key="indexAula">
                          <v-expansion-panel-header class="indigo lighten-5">
                            <template v-slot:default="{ open }">
                              <v-row no-gutters>
                                <v-col cols="4">{{ aula.title }}</v-col>
                                <v-col cols="8" class="text--secondary">
                                  <v-fade-transition leave-absolute>
                                    <span v-if="open" key="0">
                                      Preencha com as informações da aula
                                    </span>
                                    <span v-else key="1">
                                      {{ aula.duracao }}
                                    </span>
                                  </v-fade-transition>
                                </v-col>
                              </v-row>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-text-field
                              v-model="aula.title"
                              label="Título da aula"
                              placeholder="Escreva o título da aula"
                            ></v-text-field>
                            <v-text-field
                              v-model="aula.subtitle"
                              label="Subtítulo (Duração da aula)"
                              placeholder="Escreva o Subtítulo (Geralmente é a duração da aula)"
                            ></v-text-field>
                            <v-text-field
                              v-model="aula.videoId"
                              label="ID do vídeo no Youtube"
                              placeholder="c7mLQiSyjRU"
                              persistent-hint
                              hint="Ex: https://www.youtube.com/watch?v=c7mLQiSyjRU. Logo o ID é c7mLQiSyjRU"
                            ></v-text-field>
                            <v-text-field
                              :value="indexAula"
                              disabled
                              label="Hash"
                            ></v-text-field>
                            <v-card>
                              <v-app-bar color="purple" class="my-5" dark>
                                <v-toolbar-title
                                  >Material Complementar
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <material-complementar :curso="curso" :moduloKey="index" :aulaKey="indexAula"></material-complementar>
                                <!-- <v-tooltip v-model="show" top>
                                  <v-file-input
                                    :ref="
                                      'materialComplementar' + index + indexAula
                                    "
                                    class="d-none"
                                    accept="application/pdf"
                                    type="file"
                                    @change="onFileChangedMaterial"
                                  ></v-file-input>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="mx-2"
                                      fab
                                      dark
                                      color="purple"
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="
                                        adicionarArquivo(index, indexAula)
                                      "
                                    >
                                      <v-icon dark> mdi-plus </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Adicionar Material Complementar</span>
                                </v-tooltip> -->
                              </v-app-bar>
                              <v-list subheader two-line>
                                <v-list-item
                                  v-for="(file, indexArquivo) in filteredFiles(
                                    index,
                                    indexAula
                                  )"
                                  :key="file.title"
                                >
                                  <v-list-item-avatar>
                                    <v-icon class="grey lighten-1" dark>
                                      mdi-clipboard-text
                                    </v-icon>
                                  </v-list-item-avatar>

                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-text="file.title"
                                      class="text-left"
                                    ></v-list-item-title>

                                    <v-list-item-subtitle
                                      class="text-left"
                                      v-text="file.size + ' Bytes'"
                                    ></v-list-item-subtitle>
                                  </v-list-item-content>

                                  <v-list-item-action>
                                    <v-btn
                                      icon
                                      @click="
                                        removerArquivo(
                                          index,
                                          indexAula,
                                          indexArquivo
                                        )
                                      "
                                    >
                                      <v-icon color="red lighten-1"
                                        >mdi-delete</v-icon
                                      >
                                    </v-btn>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                            </v-card>
                            <v-btn
                              color="default"
                              class="mr-5"
                              @click.native="excluirAula(index, indexAula)"
                            >
                              Excluir aula
                            </v-btn>
                            <v-btn
                              color="success"
                              class="mr-0"
                              @click.native="
                                atualizarAula(aula, index, indexAula)
                              "
                            >
                              Atualizar aula
                            </v-btn>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </template>
                    </v-expansion-panels>
                    <v-btn
                      color="default"
                      class="mr-5"
                      @click.native="excluirModulo(index)"
                    >
                      Excluir Módulo
                    </v-btn>
                    <v-btn
                      color="success"
                      class="mr-0"
                      @click.native="atualizarModulo(modulo, index)"
                    >
                      Atualizar Módulo
                    </v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
            </v-expansion-panels>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <progress-dialog
      :text="textDialog"
      :dialog="dialogProgress"
      :value="uploadValue"
    ></progress-dialog>
  </v-container>
</template>
<script>
import toastr from "toastr";
import firebase from "firebase/app";
import firebasestorage from "firebase/storage";
import { db } from "@/db";
import { mapState } from "vuex";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Import translations for the German language.
import "@ckeditor/ckeditor5-build-classic/build/translations/pt-br";
import ProgressDialog from "@/components/base/ProgressDialog.vue";
import MaterialComplementar from "@/views/dashboard/components/core/MaterialComplementar.vue";

// import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
// import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
// import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
// import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
// import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";

export default {
  name: "Curso",
  components: { ProgressDialog,MaterialComplementar },
  firebase() {
    let ref = "/cursos/" + this.$route.params.curso;
    // let url = "/cursos/" + this.$route.params.curso + "/aulas/" + this.$route.params.id;
    // let ref2 = db.ref(url)
    // let ementa = "/cursos/" + this.$route.params.curso + "/ementa/";
    return {
      curso: db.ref(ref),
      //   aula: ref2,
      //   ementa: db.ref(ementa),
    };
  },
  computed: {
    ...mapState(["avatar"]),
  },
  data: () => ({
    uploadValue: 0,
    selectedFile: null,
    selectedFileCertificado: null,
    selectedFileGuia: null,
    selectedFileMaterial: null,
    referenciaArquivo: {
      index: null,
      indexAula: null,
    },
    textDialog: "Realizando o Upload de arquivo...",
    dialogProgress: false,
    url: null,
    urlCertificado: null,
    urlGuia: null,
    urlMaterial: null,
    mudarSenha: false,
    show: false,
    folders: [
      {
        subtitle: "Jan 9, 2014",
        title: "Photos",
      },
      {
        subtitle: "Jan 17, 2014",
        title: "Recipes",
      },
      {
        subtitle: "Jan 28, 2014",
        title: "Work",
      },
    ],
    modulos: [],
    aulas: [],
    editor: ClassicEditor,
    editorData: "<p>Descrição geral sobre o curso</p>",
    editorConfig: {
      language: "pt-br",
      //   plugins: [
      //     EssentialsPlugin,
      //     BoldPlugin,
      //     ItalicPlugin,
      //     LinkPlugin,
      //     ParagraphPlugin,
      //   ],

      //   toolbar: {
      //     items: ["bold", "italic", "link", "undo", "redo"],
      //   },
    },
  }),
  methods: {
    removerArquivo(index, indexAula, indexArquivo) {
      toastr.info("Removendo arquivo...", "Confabular");
      // this.dialogProgress = true;
      // this.textDialog = "Removendo arquivo...";
      this.uploadValue = 0;

      console.log(
        this.curso.ementa[index].children[indexAula].arquivos[indexArquivo]
      );
      delete this.curso.ementa[index].children[indexAula].arquivos[
        indexArquivo
      ];
      delete this.curso.aulas[indexAula].arquivos[indexArquivo];
      this.atualizar();
      // this.uploadValue = 100;
      // this.dialogProgress = false;
    },
    filteredFiles(index, indexAula) {
      let arquivos = this.curso.ementa[index].children[indexAula].arquivos;
      // console.log(arquivos);
      return arquivos;
    },
    excluirGuia() {
      this.curso.guia = null;
      this.curso.guiaFileName = null;
      this.atualizar();
    },
    adicionarAula(modulo, index) {
      let qtd = 0;
      if (modulo.children) {
        qtd = Object.keys(modulo.children).length;
      }
      let aula = {
        subtitle: "",
        video: true,
        videoId: "",
        title: "Aula " + (qtd + 1),
      };
      this.atualizarAula(aula, index, null);
    },
    adicionarModulo() {
      let qtd = 0;
      if (this.curso.ementa) {
        qtd = Object.keys(this.curso.ementa).length;
      }
      let modulo = {
        children: [],
        curso: true,
        duracao: "",
        group: "",
        text: false,
        title: "Módulo " + (qtd + 1),
      };
      this.atualizarModulo(modulo, null);
    },
    onFileChangeCertificado(file) {
      this.selectedFileCertificado = file;
      this.urlCertificado = URL.createObjectURL(file);
      this.onUploadCertificado();
    },
    onFileChangedGuia(file) {
      this.selectedFileGuia = file;
      this.urlGuia = URL.createObjectURL(file);
      this.onUploadGuia();
    },
    onFileChangedMaterial(file) {
      // console.log(file)
      this.selectedFileMaterial = file;
      this.urlMaterial = URL.createObjectURL(file);
      this.onUploadMaterial();
    },
    onFileChanged(file) {
      this.selectedFile = file;
      this.url = URL.createObjectURL(file);
      this.onUpload();
    },
    onUploadCertificado() {
      const fileName = this.selectedFileCertificado.name;
      const storageRef = firebase
        .storage()
        .ref(fileName)
        .put(this.selectedFileCertificado);
      // Upload photoURL.
      this.textDialog = "Realizando o upload da imagem do Certificado!";
      toastr.info(this.textDialog, "Confabular");
      this.dialogProgress = true;
      storageRef.on(
        "state_changed",
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref
            .getDownloadURL()
            .then((urlCertificado) => {
              console.log(urlCertificado);
              //delete this.user.photoURL
              this.textDialog = "Upload da imagem realizado!";
              toastr.success(this.textDialog, "Confabular");
              this.dialogProgress = false;
              this.curso.certificado = urlCertificado;
              this.atualizar();
            })
            .catch((error) => {
              toastr.error(
                "Algo Inesperado aconteceu: " + error.message,
                "Confabular"
              );
            });
        }
      );
    },
    onUploadGuia() {
      const fileName = this.selectedFileGuia.name;
      const storageRef = firebase
        .storage()
        .ref(fileName)
        .put(this.selectedFileGuia);
      // Upload photoURL.
      toastr.info("Realizando o upload do Guia do Curso!", "Confabular");
      this.textDialog = "Realizando o upload do Guia do Curso!";
      this.dialogProgress = true;
      storageRef.on(
        "state_changed",
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref
            .getDownloadURL()
            .then((urlGuia) => {
              console.log(urlGuia);
              //delete this.user.photoURL
              toastr.success("Upload da imagem realizado!", "Confabular");
              this.textDialog = "Upload da imagem realizado!";
              this.dialogProgress = false;
              this.curso.guia = urlGuia;
              this.curso.guiaFileName = fileName;
              this.atualizar();
            })
            .catch((error) => {
              toastr.error(
                "Algo Inesperado aconteceu: " + error.message,
                "Confabular"
              );
            });
        }
      );
    },
    onUploadMaterial() {
      const fileName = this.selectedFileMaterial.name;
      const storageRef = firebase
        .storage()
        .ref(fileName)
        .put(this.selectedFileMaterial);
      // Upload photoURL.
      toastr.info(
        "Realizando o upload de Material Complementar!",
        "Confabular"
      );
      // let tamanho = 0
      let arquivo = {};
      storageRef.on(
        "state_changed",
        (snapshot) => {
          // tamanho = snapshot.totalBytes
          arquivo = snapshot;
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref
            .getDownloadURL()
            .then((urlFile) => {
              //delete this.user.photoURL
              toastr.success("Upload de arquivo realizado!", "Confabular");
              console.log(arquivo);
              let file = {};
              if (arquivo && arquivo.metadata) {
                file = {
                  title: fileName,
                  url: urlFile,
                  timeCreated: arquivo.metadata.timeCreated,
                  size: arquivo.metadata.size,
                  contentType: arquivo.metadata.contentType,
                };
              } else {
                file = {
                  title: fileName,
                  url: urlFile,
                  timeCreated: "",
                  size: arquivo.totalBytes,
                  contentType: "",
                };
              }

              if (
                !this.curso.ementa[this.referenciaArquivo.index].children[
                  this.referenciaArquivo.indexAula
                ].arquivos
              ) {
                this.curso.ementa[this.referenciaArquivo.index].children[
                  this.referenciaArquivo.indexAula
                ].arquivos = [];
              }
              console.log(
                this.curso.ementa[this.referenciaArquivo.index].children[
                  this.referenciaArquivo.indexAula
                ].arquivos
              );
              this.curso.ementa[this.referenciaArquivo.index].children[
                this.referenciaArquivo.indexAula
              ].arquivos.push(file);

              this.curso.aulas[
                this.referenciaArquivo.indexAula
              ] = this.curso.ementa[this.referenciaArquivo.index].children[
                this.referenciaArquivo.indexAula
              ];
              // console.log(this.curso)

              // console.log(tamanho)
              this.atualizar();
            })
            .catch((error) => {
              console.log(error);
              toastr.error(
                "Algo Inesperado aconteceu: " + error.message,
                "Confabular"
              );
            });
        }
      );
    },
    onUpload() {
      const fileName = this.selectedFile.name;
      const storageRef = firebase
        .storage()
        .ref(fileName)
        .put(this.selectedFile);
      // Upload photoURL.
      toastr.info("Realizando o upload da imagem!", "Confabular");
      storageRef.on(
        "state_changed",
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref
            .getDownloadURL()
            .then((url) => {
              //delete this.user.photoURL
              toastr.success("Upload da imagem realizado!", "Confabular");
              this.curso.thumbnail = url;
              this.atualizar();
            })
            .catch((error) => {
              toastr.error(
                "Algo Inesperado aconteceu: " + error.message,
                "Confabular"
              );
            });
        }
      );
    },
    uploadImagem() {
      //this.$refs.foto.click()
      // console.log(this)
      this.$refs.foto.$refs.input.click();
    },
    uploadCertificado() {
      //this.$refs.foto.click()
      // console.log(this)
      this.$refs.certificado.$refs.input.click();
    },
    uploadGuia() {
      //this.$refs.foto.click()
      // console.log(this)
      this.$refs.guia.$refs.input.click();
    },
    adicionarArquivo(index, indexAula) {
      let ref = "materialComplementar" + index + indexAula;
      this.referenciaArquivo.index = index;
      this.referenciaArquivo.indexAula = indexAula;
      this.$refs[ref][0].$refs.input.click();
    },
    atualizar() {
      //Salvar o curso
      const ref = "cursos/" + this.curso[".key"];
      const curso = this.curso;
      // console.log(ref)
      firebase
        .database()
        .ref(ref)
        .set(curso)
        .then(() => {
          // Update successful.
          toastr.success("Curso Atualizado com sucesso!", "Confabular");
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Confabular"
          );
        });
      // this.modulos = [];
      //Verificar a possibilidade de atualizar o curso nos usuários matriculados
    },
    inserirAula(aula, index, texto) {
      let ref = "cursos/" + this.curso[".key"] + "/aulas/" + index;
      firebase
        .database()
        .ref(ref)
        .set(aula)
        .then(() => {
          // Update successful.
          toastr.success(texto, "Confabular");
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Confabular"
          );
        });
    },
    deletarAula(index, texto) {
      let ref = "cursos/" + this.curso[".key"] + "/aulas/" + index;
      firebase
        .database()
        .ref(ref)
        .remove()
        .then(() => {
          // Update successful.
          toastr.success(texto, "Confabular");
          this.aulas = [];
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Confabular"
          );
        });
    },
    excluirAula(index, indexAula) {
      let ref =
        "cursos/" + this.curso[".key"] + "/ementa/" + index + "/children/";
      if (indexAula !== null) {
        ref = ref + indexAula;
        // console.log(ref);
        firebase
          .database()
          .ref(ref)
          .remove()
          .then(() => {
            // Update successful.
            this.deletarAula(indexAula, "Aula excluída com sucesso!");
            // toastr.success("Aula Atualizada com sucesso!", "Confabular");
          })
          .catch((error) => {
            toastr.error(
              "Algo Inesperado aconteceu: " + error.message,
              "Confabular"
            );
          });
      }

      // this.aulas = [];
    },
    atualizarAula(aula, index, indexAula) {
      let ref =
        "cursos/" + this.curso[".key"] + "/ementa/" + index + "/children/";
      if (indexAula !== null) {
        ref = ref + indexAula;
        // console.log(ref);
        firebase
          .database()
          .ref(ref)
          .set(aula)
          .then(() => {
            // Update successful.
            this.inserirAula(aula, indexAula, "Aula Atualizada com sucesso!");
            // toastr.success("Aula Atualizada com sucesso!", "Confabular");
          })
          .catch((error) => {
            toastr.error(
              "Algo Inesperado aconteceu: " + error.message,
              "Confabular"
            );
          });
      } else {
        // console.log(ref);
        firebase
          .database()
          .ref(ref)
          .push(aula)
          .then((snapshot) => {
            // Update successful.
            indexAula = snapshot.key;
            this.inserirAula(aula, indexAula, "Aula Inserida com sucesso!");
            // toastr.success("Aula Inserida com sucesso!", "Confabular");
          })
          .catch((error) => {
            toastr.error(
              "Algo Inesperado aconteceu: " + error.message,
              "Confabular"
            );
          });
        // console.log(response);
      }

      // this.aulas = [];
    },
    atualizarModulo(modulo, index) {
      let ref = "cursos/" + this.curso[".key"] + "/ementa/";
      if (index !== null) {
        ref = "cursos/" + this.curso[".key"] + "/ementa/" + index;
        firebase
          .database()
          .ref(ref)
          .set(modulo)
          .then(() => {
            // Update successful.
            toastr.success("Módulo Atualizado com sucesso!", "Confabular");
          })
          .catch((error) => {
            toastr.error(
              "Algo Inesperado aconteceu: " + error.message,
              "Confabular"
            );
          });
      } else {
        firebase
          .database()
          .ref(ref)
          .push(modulo)
          .then(() => {
            // Update successful.
            toastr.success("Módulo Inserido com sucesso!", "Confabular");
          })
          .catch((error) => {
            toastr.error(
              "Algo Inesperado aconteceu: " + error.message,
              "Confabular"
            );
          });
      }

      this.modulos = [];
    },
    excluirModulo(index) {
      let ref = "cursos/" + this.curso[".key"] + "/ementa/";
      if (index !== null) {
        ref = "cursos/" + this.curso[".key"] + "/ementa/" + index;
        firebase
          .database()
          .ref(ref)
          .remove()
          .then(() => {
            // Update successful.
            toastr.success("Módulo excluído com sucesso!", "Confabular");
          })
          .catch((error) => {
            toastr.error(
              "Algo Inesperado aconteceu: " + error.message,
              "Confabular"
            );
          });
      }

      this.modulos = [];
    },
  },
};
</script>
